import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container, BlogPostGrid } from '../styles/globalStyles';
import PostTags from '../components/PostTags';
import slugify from 'slugify';

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  group.sort((a, b) => b.count - a.count);
  return (
    <Layout>
      <Container>
        <Helmet title={title} />
        <BlogPostGrid>
          <h1>Tags</h1>
          <p></p>
          <PostTags
            tags={group.map((i) => ({
              ...i,
              url: slugify(i.title),
            }))}
          />
          <p></p>
        </BlogPostGrid>
      </Container>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        title: fieldValue
        url: fieldValue
        count: totalCount
      }
    }
  }
`;
